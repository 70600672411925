import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import Helmet from "react-helmet"
import "./blog.css"
import Categoria from "./categoria"

const Blog = ({ children, site }) => {
  <Helmet>
    <script
      src={withPrefix("js/code.jquery.com_jquery-1.12.4.min.js")}
    ></script>
    <script src={withPrefix("js/paginador.js")}></script>
  </Helmet>

  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index
  }

  const query = useStaticQuery(graphql`
    query Blog_gs {
      allStrapiBlogs(sort: { order: DESC, fields: id }) {
        edges {
          node {
            Contenido
            Estado
            Imagen {
              url
            }

            Palabra_clave
            Categoria
            Titulo
            Slug
            id
            Site
          }
        }
        pageInfo {
          totalCount
        }
      }
    }
  `)

  let url_global = process.env.GATSBY_API_URL
  let projects = query.allStrapiBlogs.edges
  function goTolink(link) {
    // console.log("QUE ME LLEGO", link)
    if (typeof window !== "undefined") {
      window.localStorage.setItem("paramblog", link)
      // console.log(localStorage.getItem('param'));
    }
  }
  var estados = []

  projects.map(({ node: row }) => {
    if (row.Categoria && row.Estado === "Ocultar") {
      estados.push(row.Estado)
    }
    if (row.Categoria && row.Estado === "Mostrar") {
      estados.push(row.Estado)
    }
  })

  var uniqueEstados = estados.filter(onlyUnique)

  return (
    <>
      <div className="mitexto">
        <div className="relative alinear_movil_blog mx-auto container justify-items-center lg:grid grid-cols-3 gap-4 mt-64 pt-32">
          <div className="col-span-2 orden_blog">
            <div className="relative centrado_vertical lg:inline-grid gap-4 grid-cols-2">
              {/* PARA VERSION MOVIL */}
              {/* {projects.map(({ node: project }, index) => {
                if (project.Estado === "Mostrar") {
                  return (
                    <div
                      key={index}
                      className="hidden alinear_categoria categoria_oculta"
                    >
                      <Categoria />
                    </div>
                  )
                }
              })} */}

              {/* PARA VERSION MOVIL */}

              {projects.map(({ node: project }) => {
                if (project.Estado === "Mostrar") {
                  return (
                    <div className="list-wrapper" key={project.id}>
                      <div className="list-item" style={{ display: "block" }}>
                        <div className="square_sombra transform hover:-translate-y-1 hover:scale-110 transition duration-500">
                          <a
                            // href={"/detalleblogs/?id=" + project.id}
                            href={`/blogs/detalle/?${project.Slug}`}
                            target="_self"
                            onClick={() => {
                              goTolink(project.Slug)
                            }}
                          >
                            <div className="square_sombra mitexto">
                              <img
                                alt="imagen_blog"
                                className="img_dos mask"
                                src={url_global + "" + project.Imagen[0]?.url}
                                alt={project.Titulo}
                                title={project.Titulo}
                              />
                              <div className="h1 mitexto mitexto_color color-texto-parrafo text-poppins-bold">
                                {project.Titulo}
                              </div>
                              <div
                                className="mitexto_color ancho_tarjetas_texto"
                                style={{
                                  position: "relative",
                                  top: "1rem",
                                  overflowY: "hidden",
                                  overflowX: "hidden",
                                  gridArea: "1/1",
                                  maxHeight: "100px",
                                  maxWidth: "321px",
                                  paddingLeft: "26px",
                                  textAlign: "justify",
                                  marginBottom: "2rem",
                                }}
                                layout="fullWidth"
                                dangerouslySetInnerHTML={{
                                  __html: project.Contenido,
                                }}
                              />

                              <p
                                className="green-secundary text-poppins-bold"
                                style={{ paddingLeft: "7%", fontSize: "14px" }}
                              >
                                {project.Categoria}
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
            </div>

            {uniqueEstados == "Mostrar" && (
              <div id="pagination-container"></div>
            )}
          </div>

          {uniqueEstados == "Mostrar" && (
            <div key={"index-4c"} className="alinear_categoria">
              <Categoria />
            </div>
          )}
        </div>

        {uniqueEstados == "Ocultar" && (
          <div
            key={"index-5c"}
            className="w-full mx-auto flex flex-wrap p-6 bg-white justify-center"
            style={{ paddingBottom: "15%" }}
          >
            <div className="shrink-0">
              <img
                src={url_global + "/uploads/logo_a1b429d0e0.PNG"}
                alt=""
                className="h-20 w-auto"
              />
            </div>
            <div className="ml-6 pt-1">
              <h4 className="text-xl text-gray-900 mitexto mitexto_color color-texto-parrafo text-poppins-bold">
                ¡Epsdelagente te informa!
              </h4>
              <p className="text-base text-gray-600 mitexto mitexto_color color-texto-parrafo text-poppins-regular">
                No hay blogs por el momento
              </p>
            </div>
          </div>
        )}

        {/* <!-- CARD COLUMNS --> */}
      </div>
    </>
  )
}

Blog.propTypes = {
  siteTitle: PropTypes.string,
}

Blog.defaultProps = {
  siteTitle: ``,
}

export default Blog
