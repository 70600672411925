import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./detallenoticias.css"
import Header from "../../../components/header"
import Footer from "../../../components/footer"
import Seo from "../../../components/seo"
import BotonFlotante from "../../../components/botonflotante"
import Accesibilidad from "../../../components/accesibilidad"

const DetalleNoticias = props => {
  let id_recortado = props.location.search.slice(1)
  id_recortado = id_recortado.replace("id=", "")
  console.log("recortado", id_recortado)
  // if (typeof window !== "undefined") {
  //   window.localStorage.setItem("paramnoti", id_recortado)
  //   console.log(localStorage.getItem('paramnoti'));
  // }

  const query = useStaticQuery(graphql`
    query Procesonoticias {
      allStrapiNoticias(sort: { order: DESC, fields: created_at }) {
        edges {
          node {
            Contenido
            Imagen {
              url
            }
            Palabra_clave
            Titulo
            id
            Slug
            Imagen_valla_detalle_noticia {
              url
            }
            Titulo_principal_detalle_noticia
            Titulo_secundario_detalle_noticia
          }
        }
      }
    }
  `)
  const projects = query.allStrapiNoticias.edges
  let url_global = process.env.GATSBY_API_URL
  let image_url_null =
    "url(" + url_global + "/uploads/" + "valla_03_6e3f99d9a6.jpg" + ")"

  let item = ""
  let host = ""
  if (typeof window !== "undefined") {
    host = window.location.href
    item = window.localStorage.getItem("paramnoti")
  }
  if (host == "https://www.epsdelagente.com.co/noticias/detalle/") {
    // console.log("INGRESO AQUI")
    window.location.href =
      "https://www.epsdelagente.com.co/noticias/detalle/?" + item
  }

  if (item == null) {
    window.location.href = "https://www.epsdelagente.com.co/noticias/"
  }

  return (
    <>
      <Accesibilidad />
      <Header site="personas" />

      <Seo title="Personas" />
      <BotonFlotante />

      {projects.map(({ node: project }, index) => {
        if (project.Slug === id_recortado) {
          let image_url =
            "url(" +
            url_global +
            project.Imagen_valla_detalle_noticia?.url +
            ")"
          return (
            <div key={index}>
              {project.Imagen_valla_detalle_noticia?.url === undefined && (
                <div
                  className="absolute fondo_central_image_detalle w-screen ancho_banner_detalleblog altura_banner lg:w-screen altura_banner posicion_lado_banner_ventaja -top-0 bg-cover bg-right"
                  title="valla detalle noticia"
                  style={{
                    zIndex: "0",
                    backgroundImage: `${image_url_null}`,
                  }}
                ></div>
              )}

              {project.Imagen_valla_detalle_noticia?.url !== undefined && (
                <div
                  className="absolute fondo_central_image_detalle w-screen ancho_banner_detalleblog altura_banner lg:w-screen altura_banner posicion_lado_banner_ventaja -top-0 bg-cover bg-right"
                  title="valla detalle noticia"
                  style={{
                    zIndex: "0",
                    backgroundImage: `${image_url}`,
                  }}
                ></div>
              )}

              {project.Titulo_principal_detalle_noticia === null && (
                <div
                  className="detalle_blog_sm leading-none mx-auto container text-poppins-bold lg:relative titulo_ventaja_lg"
                  style={{
                    color: "#256238",
                    top: "5rem",
                  }}
                >
                  Sin {""}
                  <span className="green-secundary">detalle</span>
                </div>
              )}

              {project.Titulo_principal_detalle_noticia !== null && (
                <div
                  className="detalle_blog_sm leading-none mx-auto container text-poppins-bold lg:relative titulo_ventaja_lg"
                  style={{
                    color: "#256238",
                    top: "5rem",
                  }}
                >
                  {project.Titulo_principal_detalle_noticia}{" "}
                  <span className="green-secundary">
                    {project.Titulo_secundario_detalle_noticia}
                  </span>
                </div>
              )}
              <div
                style={{
                  position: "relative",
                  marginTop: "15rem",
                  paddingLeft: "16rem",
                  paddingRight: "16rem",
                }}
                className="mitexto container_dos ancho_contenido_blog_sm mx-auto w-full justify-items-center px-2 pt-4 pb-12 text-gray-800 espacios_lados_blog"
              >
                <div className="grid grid-cols-1 divide-y-2 divide-gray-200 md:divide-y-2">
                  <div className="content py-12 text-justify text-poppins-regular color-texto-parrafo">
                    <div className="mitexto">
                      <p
                        className="mitexto_color title text-poppins-bold green-primary color-texto-parrafo"
                        style={{ position: "relative text-left" }}
                      >
                        {project.Titulo}
                      </p>

                      <br></br>

                      <div
                        className="mitexto color-texto-parrafo"
                        style={{
                          position: "relative",
                          marginBottom: "3rem",
                          gridArea: "1/1",
                          overflow: "hidden",
                        }}
                        layout="fullWidth"
                        dangerouslySetInnerHTML={{ __html: project.Contenido }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })}
      <Footer />
    </>
  )
}

DetalleNoticias.propTypes = {
  siteTitle: PropTypes.string,
}

DetalleNoticias.defaultProps = {
  siteTitle: ``,
}

export default DetalleNoticias
