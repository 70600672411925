import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "./detalleblogs.css"
import Header from "../../../components/header"
import Footer from "../../../components/footer"
import Seo from "../../../components/seo"
import Accesibilidad from "../../../components/accesibilidad"
import BotonFlotante from "../../../components/botonflotante"

const Procesoblog = props => {
  let id_recortado = props.location.search.slice(1)
  id_recortado = id_recortado.replace("id=", "")
  console.log("recortado", id_recortado)
  // if (typeof window !== "undefined") {
  //   window.localStorage.setItem("paramblog", id_recortado)
  //   // console.log(localStorage.getItem("paramblog"))
  // }
  const query = useStaticQuery(graphql`
    query Procesoblog {
      allStrapiBlogs(sort: { order: DESC, fields: id }) {
        edges {
          node {
            Contenido
            Imagen {
              url
            }
            Palabra_clave
            Titulo
            id
            Slug
            Imagen_valla_detalle_blog {
              url
            }
            Titulo_principal_detalle_blog
            Titulo_secundario_detalle_blog
          }
        }
      }
    }
  `)
  const projects = query.allStrapiBlogs.edges
  let url_global = process.env.GATSBY_API_URL
  let image_url_null =
    "url(" + url_global + "/uploads/" + "valla_03_6e3f99d9a6.jpg" + ")"
  let item = ""
  let host = ""
  if (typeof window !== "undefined") {
    item = window.localStorage.getItem("paramblog")
    host = window.location.href
  }
  if (host == "https://www.epsdelagente.com.co/blogs/detalle/") {
    // console.log("INGRESO AQUI")
    window.location.href =
      "https://www.epsdelagente.com.co/blogs/detalle/?" + item
  }
  if (item == null) {
    window.location.href = "https://www.epsdelagente.com.co/blogs/"
  }

  return (
    <>
      <Accesibilidad />
      <Header site="personas" />
      <Seo title="Personas" />
      <BotonFlotante style={{ display: "block" }}></BotonFlotante>
      {projects.map(({ node: project }, index) => {
        if (project.Slug === id_recortado) {
          let image_url =
            "url(" + url_global + project.Imagen_valla_detalle_blog.url + ")"

          return (
            <div key={index}>
              {project.Imagen_valla_detalle_blog.url === undefined && (
                <div
                  className="absolute fondo_central_image_detalle w-screen ancho_banner_detalleblog altura_banner lg:w-screen altura_banner posicion_lado_banner_ventaja -top-0 bg-cover bg-right"
                  title="valla detalle blog"
                  style={{
                    zIndex: "0",
                    backgroundImage: `${image_url_null}`,
                  }}
                ></div>
              )}

              {project.Imagen_valla_detalle_blog.url !== undefined && (
                <div
                  className="absolute fondo_central_image_detalle w-screen ancho_banner_detalleblog altura_banner lg:w-screen altura_banner posicion_lado_banner_ventaja -top-0 bg-cover bg-right"
                  title="valla detalle blog"
                  style={{
                    zIndex: "0",
                    backgroundImage: `${image_url}`,
                  }}
                ></div>
              )}

              {project.Titulo_principal_detalle_blog === null && (
                <div
                  className="detalle_blog_sm leading-none mx-auto container text-poppins-bold lg:relative titulo_ventaja_lg"
                  style={{
                    color: "#256238",
                    top: "5rem",
                  }}
                >
                  Sin <span className="green-secundary">detalle</span>
                </div>
              )}

              {project.Titulo_principal_detalle_blog !== null && (
                <div
                  className="detalle_blog_sm leading-none mx-auto container text-poppins-bold lg:relative titulo_ventaja_lg"
                  style={{
                    color: "#256238",
                    top: "5rem",
                  }}
                >
                  {project.Titulo_principal_detalle_blog}{" "}
                  <span className="green-secundary">
                    {project.Titulo_secundario_detalle_blog}
                  </span>
                </div>
              )}
              <div
                style={{
                  position: "relative",
                  marginTop: "15rem",
                  paddingLeft: "16rem",
                  paddingRight: "16rem",
                }}
                className="container_dos mitexto ancho_contenido_blog_sm mx-auto w-full justify-items-center px-2 pt-4 pb-12 text-gray-800 espacios_lados_blog"
              >
                <div className="grid grid-cols-1 divide-y-2 divide-gray-200 md:divide-y-2">
                  <div className="content py-12 text-justify text-poppins-regular color-texto-parrafo">
                    <div className="mitexto">
                      <p
                        className="mitexto_color title text-poppins-bold green-primary color-texto-parrafo"
                        style={{ position: "relative text-left" }}
                      >
                        {project.Titulo}
                      </p>

                      <br></br>

                      <div
                        className="mitexto_color color-texto-parrafo"
                        style={{
                          position: "relative",
                          marginBottom: "3rem",
                          gridArea: "1/1",
                          overflow: "hidden",
                        }}
                        layout="fullWidth"
                        dangerouslySetInnerHTML={{ __html: project.Contenido }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })}

      <Footer />
    </>
  )
}

Procesoblog.propTypes = {
  siteTitle: PropTypes.string,
}

Procesoblog.defaultProps = {
  siteTitle: ``,
}

export default Procesoblog
