import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./servicioenlinea.css"
import Header from "../../components/header"
import Footer from "../../components/footer"
import BotonFlotante from "../../components/botonflotante"
import Seo from "../../components/seo"
import Accesibilidad from "../../components/accesibilidad"

const Procesoservicioenlinea = props => {
// const Procesoservicioenlinea = ({ recortado }) => {
  let id_recortado = props.location.search.slice(1)
  // let id_recortado = recortado
  console.log("recortado", id_recortado)
  const query = useStaticQuery(graphql`
    query Procesoservicioenlinea {
      allStrapiServicioOnlines {
        edges {
          node {
            id
            Slug
            Titulo
            Contenido
            Enlace_externo
            Orden
            Icono {
              url
            }
          }
        }
      }
    }
  `)
  const projects = query.allStrapiServicioOnlines.edges
  let host = ""
  // let param1 = "" // valor1
  // let param2 = "" // valor2
  // let parametros = ""
  // let hash = ""
  let item = ""
  if (typeof window !== "undefined") {
    item = window.localStorage.getItem("param")
    host = window.location.href
    // hash = window.location.hash
    // let urlAntesDeRedirigir = window.location.href;
    // console.log("URL antes de redirigir:", urlAntesDeRedirigir);
    // window.addEventListener("beforeunload", function (event) {
    //   let urlAntesDeRedirigir = window.location
    //   console.log("URL antes de redirigir:", urlAntesDeRedirigir)
    // })
  }
  // console.log("url completa", host)

  // if (host == "https://www.epsdelagente.com.co/detalleservicioenlinea/") {
  //   // console.log("INGRESO AQUI")
  //   window.location.href =
  //     "https://www.epsdelagente.com.co/detalleservicioenlinea/?" + item
  // }

  if (host == "https://www.epsdelagente.com.co/detalleservicioenlinea/") {
    // console.log("INGRESO AQUI")
    window.location.href =
      "https://www.epsdelagente.com.co/detalleservicioenlinea/?" + item
  }
  if (item == null) {
    window.location.href = "https://www.epsdelagente.com.co/salud_en_linea/"
  }
  return (
    <>
      <Accesibilidad />
      <Header site="personas" />

      <Seo title="" site="personas" />

      {projects.map(({ node: project }, index) => {
        // console.log("Si ingreso", item)
        if (project.Slug === item) {
          return (
            <div className="mitexto" key={index}>
              <div
                className="text-poppins-regular color-texto-parrafo"
                dangerouslySetInnerHTML={{
                  __html: project.Contenido,
                }}
              ></div>
              <br></br>
              <br></br>
              <br></br>
              <BotonFlotante style={{ display: "block" }}></BotonFlotante>

              {project.Enlace_externo !== "" && (
                <div className="mx-auto pb-20 mitexto margen_detalle_servicio_linea_texto w-full 2xl:w-4/5 xl:w-4/5 lg:w-3/4 md:w-3/4">
                  <iframe
                    className="mx-auto alto_iframe_servicio_linea px-3 w-11/12 xl:w-8/12 px-0 lg:w-3/4 px-0 md:w-3/4 px-0"
                    src={project.Enlace_externo}
                  ></iframe>
                </div>
              )}

              {project.Enlace_externo === "" && (
                <div className="margen_detalle_servicio_linea_texto"></div>
              )}
            </div>
          )
        }
      })}

      <Footer />
    </>
  )
}

Procesoservicioenlinea.propTypes = {
  siteTitle: PropTypes.string,
}

Procesoservicioenlinea.defaultProps = {
  siteTitle: ``,
}

export default Procesoservicioenlinea
