import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Servivio from "../../components/servicio_en_linea"
// import DetalleServivio from "../detalleservicioenlinea/"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Seo from "../../components/seo"
import Accesibilidad from "../../components/accesibilidad"
import ModalPopup from "../../components/modal_popup"

const Detalleservicioenlinea = props => {
  const query = useStaticQuery(graphql`
    query Detalleservicioenlinea {
      allStrapiPages {
        edges {
          node {
            content
            site
            slug
          }
        }
      }
    }
  `)
  const projects = query.allStrapiPages.edges
  // let hostrecortado = ""
  // let validador = false
  // let host = ""
  // let hostlinea = "http://localhost:8000/salud_en_linea/"
  // let hostlineaservidor = "https://www.epsdelagente.com.co/salud_en_linea/"
  // if (typeof window !== "undefined") {
  //   host = window.location.href

  //   // console.log("HOST", typeof host)
  //   // console.log("HOSTS", typeof hostlinea)
  //   // console.log("HOSTS VALIDADOR", host.length)
  //   // console.log("HOSTS VALIDADOR 2", hostlineaservidor.length)
  //   // hostrecortado = host.slice(41)
  // }

  return (
    // || host === hostlineaservidor
    <>
      {projects.map(({ node: project }, index) => {
        if (project.slug === "salud_en_linea") {
          // console.log("INGRESO AQUI", host)
          return (
            <div key={`servi-${index}`}>
              <Accesibilidad />
              <Header site="personas" />
              <Seo title="Personas" />
              <ModalPopup site="personas" slug={"salud_en_linea"} />
              <div
                className="mitexto_color"
                layout="fullWidth"
                dangerouslySetInnerHTML={{
                  __html: project.content,
                }}
              />
              <br></br>
              <br></br>
              <br></br>

              <Servivio></Servivio>

              <Footer />
            </div>
          )
          // } else if (project.slug === "salud_en_linea" && host.length > 40) {
          //   {
          //     console.log("INGRESO POR ACA")
          //   }
          //   return (

          //     <DetalleServivio recortado={host.slice(41)}></DetalleServivio>
          //   )
        }
        // else if (project.slug === "salud_en_linea" && host.length > 47) {
        //   {
        //     console.log("INGRESO POR ACA")
        //   }
        //   return (
        //     <DetalleServivio recortado={host.slice(48)}></DetalleServivio>

        //   )
        // }
      })}
    </>
  )
}

Detalleservicioenlinea.propTypes = {
  siteTitle: PropTypes.string,
}

Detalleservicioenlinea.defaultProps = {
  siteTitle: ``,
}

export default Detalleservicioenlinea
