import React from "react"
import { graphql, withPrefix } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PersonasIndexPage from "../pages/personas/index"
import EmpresasIndexPage from "../pages/empresas/index"
import ProveedoresIndexPage from "../pages/proveedores/index"
import Helmet from "react-helmet"
import Noticias from "../pages/noticias/index"
import Blogs from "../pages/blogs/index"
import CitasMedicas from "../pages/citas-medicas/index"
import Servivio from "../pages/servicio_en_linea/index"

const PageTemplate = ({ data }) => {
  let page

  if (data.strapiPages.site === "personas" && data.strapiPages.slug === "/") {
    page = <PersonasIndexPage />
  } else if (
    data.strapiPages.site === "empresas" &&
    data.strapiPages.slug === "/"
  ) {
    page = <EmpresasIndexPage />
  } else if (
    data.strapiPages.site === "proveedores" &&
    data.strapiPages.slug === "/"
  ) {
    page = <ProveedoresIndexPage />
  } else if (data.strapiPages.slug === "noticias") {
    page = <Noticias site={null} />
  } else if (data.strapiPages.slug === "blogs") {
    page = <Blogs site={null} />
  } else if (data.strapiPages.slug === "citas-medicas") {
    page = <CitasMedicas site={null} />
  } else if (data.strapiPages.slug === "salud_en_linea") {
    page = <Servivio site={null} />
  } else {
    page = (
      <Layout
        site={data.strapiPages.site || "personas"}
        page={data.strapiPages.slug || "/"}
      >
        <Seo title={data.strapiPages.title} />
        <section className="page-content">
          <div
            dangerouslySetInnerHTML={{ __html: data.strapiPages.content }}
          ></div>
        </section>
      </Layout>
    )
  }

  return (
    <>
      <Helmet>
        <script
          src={withPrefix("js/code.jquery.com_jquery-1.12.4.min.js")}
        ></script>
        <script src={withPrefix("js/paginador.js")}></script>
        <script
          src={withPrefix("js/boton_flotante.js")}
          type="text/javascript"
        />
      </Helmet>
      
      {page}
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query PageTemplate($id: String!) {
    strapiPages(id: { eq: $id }) {
      id
      site
      slug
      title
      content
      created_at
    }
  }
`
