import * as React from "react"
import { withPrefix, Link } from "gatsby"
// import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Accesibilidad from "../components/accesibilidad"
// import "../components/resolucion_xiaomi.css"
// import "../components/layout.css"
// import "../styles/global.css"
// import "../../static/css/tailwind.min.css"

let url_global = process.env.GATSBY_API_URL
let url_comfenalco = "https://epsdelagente.com.co/"

// console.log("DATOS el inicio")
// if (typeof window !== "undefined") {
//   window.location.href = "/inicio"
// }
// const IndexPage = () => (
//   <div></div>
// )
const IndexPage = () => (
  <div className="h-screen w-full flex flex-wrap">
    <Accesibilidad />
    <Seo title="Bienvenidos" />

    <div className="w-full h-full imagenes_main image_main bg-cover pl-60 md:w-1/2 flex flex-col items-center justify-center">
      <div className="ubicar_imagen_pagina_principal  right-0 w-64 -top-8 pr-20 md:relative bottom-40 pr-16">
        <Link className="" to="/">
          <img
            src={url_global + "/uploads/logo_a1b429d0e0.PNG"}
            className="w-80 imagen_index"
            alt="logo"
            title="logo"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            style={{ maxWidth: "22vw" }}
          />
        </Link>
      </div>

      <div className="relative ubicar_texto_pagina_inicio">
        <h1 className="text-main bg-green-hover text-white text-poppins-extra_bold font-black text-5xl	width_text_main">
          Nuevo
        </h1>

        <h1 className="text-main bg-green-hover text-white text-poppins-extra_bold font-black text-5xl	width_text_two_main">
          Sitio web
        </h1>
        <h1 className="text-main text-green-default max-w-md text-poppins-extra_bold font-black	text-5xl">
          Evolucionamos para cuidar de ti
        </h1>
      </div>

      <div className="ubicar_imagen_icono_divisor rotacion_image_main relative left-12 bottom-48">
        <img
          src={url_global + "/uploads/bt_abajo_blanco_012289d38f.png"}
          className="tamano_icono_divisor"
          title="bt_abajo_blanco"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="bt_abajo_blanco"
          style={{ width: "103px" }}
        />
      </div>
    </div>

    <div style={{ display: "none" }} className="ubicar_enunciado">
      <p className="color-texto-parrafo text-poppins-bold">Para continuar,</p>
      <p className="color-texto-parrafo text-poppins-bold">
        selecciona tu tipo de usuario:
      </p>
    </div>

    <div
      style={{ backgroundColor: "white" }}
      className="ubicar_perfilador_rol md:pr-40 w-1/2 flex flex-col items-center justify-center h-full"
    >
      <a href={"/personas"}>
        <div className="main-menu-item shadow-md p-4 my-3	max-w-sm transform hover:-translate-y-1 hover:scale-110 transition duration-500">
          <h1 className="cambio_color text-green-light text-3xl text-poppins-extra_bold">
            Personas
          </h1>
          <p className="mitexto_color text-sm my-0 text-poppins-regular color-texto-parrafo">
            Recibe la información que requieras sobre tus servicios de salud,
            afiliaciones y citas médicas.
          </p>
        </div>
      </a>

      <a href={"/empresas"}>
        <div className="main-menu-item shadow-md p-4 my-3	max-w-sm transform hover:-translate-y-1 hover:scale-110 transition duration-500">
          <h1 className="text-green-default text-3xl text-poppins-extra_bold">
            Empresas
          </h1>
          <p className="mitexto_color text-sm  my-0 text-poppins-regular color-texto-parrafo">
            Accede a todos los datos relacionados a la afiliación de tus
            empleados, así como licencias e incapacidades.
          </p>
        </div>
      </a>

      <a href={"/proveedores"}>
        <div className="main-menu-item shadow-md p-4 my-3	max-w-sm transform hover:-translate-y-1 hover:scale-110 transition duration-500">
          <h1 className="text-gray-500 text-3xl text-poppins-extra_bold">
            Proveedores
          </h1>
          <p className="mitexto_color text-sm my-0 text-poppins-regular color-texto-parrafo">
            Ingresa y encuentra, de forma fácil y segura, todo lo relacionado a
            tus beneficios y servicios como proveedor.
          </p>
        </div>
      </a>
    </div>

    <div id="fondo-aviso-cookies" className="fondo-aviso-cookies"></div>

    <footer
      id="aviso-cookies"
      className="fixed aviso-cookies pb-4 bottom-0 text-center left-0 w-full bg-footer border-t border-gray-400 activo lg:py-8"
    >
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col lg:flex-row">
          <div className="w-full space-y-2 px-12 text-left lg:w-8/12 pt-4">
            <div className="w-full">
              <p className="w-full tamano_letra_cookies text-white text-poppins-regular">
                Para mejorar tu experiencia y recordas tus preferencias en
                próximas visitas, Utilizamos cookies.
                <br />
                <strong className="text-white text-poppins-regular">
                  <a
                    href={url_comfenalco + "politica-cookies-epsdelagente/"}
                    className="underline"
                    target="_blank"
                  >
                    Más información aquí.
                  </a>
                </strong>
              </p>
            </div>

            <div className="w-full w-full pt-0 lg:pt-4">
              <p className="w-full tamano_letra_cookies text-white text-poppins-regular">
                Dar clic en "Editar" para modificar tus preferencias. Al dar
                clic en Aceptar, aceptas todas las cookies
              </p>
            </div>
          </div>

          <div className="w-full pt-4 lg:w-4/12 pt-0">
            <div className="w-full">
              <div className="flex flex-row space-x-4 justify-center lg:justify-start">
                <div className="w-2/5 lg:w-4/12">
                  <button className="w-full buttom_cookies text-white py-2 px-7 rounded-full bg-green-hover font-semibold">
                    Aceptar
                  </button>
                </div>
                <div className="w-2/5 lg:w-4/12">
                  <button className="w-full buttom_no_cookies rounded-full text-green-600 bg-white py-2 px-7">
                    No, gracias
                  </button>
                </div>
              </div>

              <div className="w-full flex justify-center pt-4 lg:justify-start mt-4 pt-0">
                <div className="w-2/5 lg:w-4/12">
                  <button
                    id="btn-editar-cookies"
                    className="w-full text-white py-2 px-7 bg-gray-400 rounded-full font-semibold"
                  >
                    Editar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          textAlign: "center",
          zIndex: "99999999",
        }}
      >
        <div
          className="hidden hide_modal_close_cookie fixed z-10 overflow-y-scroll top-0 w-full left-0"
          id="modal"
        >
          <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block align-center bg-gray-500 rounded-3xl text-left shadow-xl transform transition-all my-16 align-middle xl:w-7/12 lg:w-7/12 md:w-7/12 sm:w-7/12"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div
                style={{ height: "40rem" }}
                className="scroll-movil overflow-y-scroll custom-overflow rounded-3xl bg-gray-500 relative h-custom-height"
              >
    
    <div
                  className="fixed boton_cerrar bottom-4 flex justify-end mr-8"
                  style={{
                    paddingLeft: "89%",
                    bottom: "97%",
                    zIndex: "9",
                  }}
                >
                  <button
                    tabIndex="1"
                    style={{ zIndex: "99" }}
                    className="hide_modal_cookie relative rotacion_icono_popup p-0 w-12 h-12 bg-white rounded-full hover:bg-gray-50 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
                  >
                    <svg viewBox="0 0 20 20" className="w-6 h-6 inline-block">
                      <path
                        fill="#c5cc0c"
                        d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601 C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399 C15.952,9,16,9.447,16,10z"
                      ></path>
                    </svg>
                  </button>
                </div>

    
    <div
                  style={{
                    display: "inline-grid",
                    verticalAlign: "middle",
                  }}
                  className="relative contenido_popup w-full text-center top-0 lg:px-40 py-8 xl:px-8 py-8 lg:px-8 py-8 md:px-8 py-8 sm:px-8 py-8"
                >
                  <p
                    className="text-poppins-extra_bold fuente_titulo text-white"
                    style={{
                      lineHeight: "2rem",
                      fontSize: "1.2em",
                    }}
                  >
                    Permisos de accesos para las cookies
                  </p>

                  <p className="text-sm text-white text-poppins-regular mt-2 text-slate-500 text-left titulo_descripcion_popup">
                    Este sitio web utiliza cookies para mejorar su experiencia
                    mientras navega por el sitio web. Estas se clasifican según
                    su finalidad y se almacenan en su navegador, ya que son
                    esenciales para el funcionamiento de funcionalidades
                    básicas. Consulta nuestra política &nbsp;
                    <a
                      style={{ cursor: "pointer" }}
                      href={url_comfenalco + "politica-cookies-epsdelagente/"}
                      target="_blank"
                      className="hide_modal_aqui mt-1 text-sm leading-tight font-medium underline"
                    >
                      Aquí
                    </a>
                  </p>
                </div>

                <div className="relative flex w-11/12 text-center mx-auto md:w-7/12">
                  <div
                    id="toogle-necesario"
                    className="h-full items-center btn-link-cookie gap-2 flex flex-cols-1 relative w-full text-center top-0"
                  >
                    <div className="grid grid-cols-1">
                      <div
                        style={{}}
                        className="relative col-span-1 swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      ></div>
                    </div>
                    <div className="grid grid-cols-2 w-full gap-1">
                      <div className="relative text-poppins-regular text-black flex">
                        <span>Necesarias</span>
                      </div>

                      <div className="text-white relative flex justify-end lg:justify-star">
                        <div className="relative flex theme-switch-wrapper">
                          <label
                            className="theme-switch"
                            htmlFor="chkNecesarias"
                          >
                            <input
                              type="checkbox"
                              id="chkNecesarias"
                              tabIndex="1"
                            />
                            <div
                              className="slider-cookie-consent round-cookie"
                              tabIndex="1"
                              id="sliderChkNecesarias"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="info_necesarias"
                  className="hidden relative btn-link-cookie flex w-11/12 text-center mx-auto md:w-7/12"
                >
                  <p className="text-left text-black">
                    Estas cookies son esenciales para que el sitio web funcione
                    correctamente. Permiten la navegación y el uso de funciones
                    básicas, como el acceso a áreas seguras del sitio. Sin
                    ellas, el sitio no podría funcionar.
                  </p>
                </div>

                <div className="relative flex w-11/12 text-center mx-auto md:w-7/12">
                  <div className="h-full items-center btn-link-cookie gap-2 flex flex-cols-1 relative w-full text-center top-0">
                    <div className="grid grid-cols-1">
                      <div
                        id="toogle-analisis"
                        className="relative col-span-1 swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      ></div>
                    </div>
                    <div className="grid grid-cols-2 w-full gap-1">
                      <div className="relative text-poppins-regular text-black flex">
                        <span>Analisis</span>
                      </div>

                      <div className="text-white relative flex justify-end lg:justify-star">
                        <div className="relative flex theme-switch-wrapper">
                          <label className="theme-switch" htmlFor="chkAnalisis">
                            <input
                              type="checkbox"
                              id="chkAnalisis"
                              tabIndex="1"
                            />
                            <div
                              className="slider-cookie-consent round-cookie"
                              tabIndex="1"
                              id="sliderChkAnalisis"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="info_analisis"
                  className="hidden relative btn-link-cookie flex w-11/12 text-center mx-auto md:w-7/12"
                >
                  <p className="text-left text-black">
                    Estas cookies nos ayudan a entender cómo los usuarios
                    interactúan con nuestro sitio. Recopilan información sobre
                    las páginas visitadas, el tiempo que se pasa en cada una y
                    posibles errores. Esta información es anónima
                  </p>
                </div>

                <div className="relative flex w-11/12 text-center mx-auto md:w-7/12">
                  <div className="h-full items-center btn-link-cookie gap-2 flex flex-cols-1 relative w-full text-center top-0">
                    <div className="grid grid-cols-1">
                      <div
                        id="toogle-publicidad"
                        className="relative col-span-1 swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      ></div>
                    </div>
                    <div className="grid grid-cols-2 w-full gap-1">
                      <div className="relative text-poppins-regular text-black flex">
                        <span>Publicidad</span>
                      </div>

                      <div className="text-white relative flex justify-end lg:justify-star">
                        <div className="relative flex theme-switch-wrapper">
                          <label
                            className="theme-switch"
                            htmlFor="chkPublicidad"
                          >
                            <input
                              type="checkbox"
                              id="chkPublicidad"
                              tabIndex="1"
                            />
                            <div
                              className="slider-cookie-consent round-cookie"
                              tabIndex="1"
                              id="sliderChkPublicidad"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="info_publicidad"
                  className="hidden relative btn-link-cookie flex w-11/12 text-center mx-auto md:w-7/12"
                >
                  <p className="text-left text-black">
                    Estas cookies se utilizan para mostrar anuncios relevantes
                    para los usuarios. Pueden recopilar información sobre
                    hábitos de navegación y preferencias, permitiendo
                    personalizar la publicidad que ves en nuestro sitio y otros
                    lugares.
                  </p>
                </div>

                <div className="relative flex w-11/12 text-center mx-auto md:w-7/12">
                  <div className="h-full items-center btn-link-cookie gap-2 flex flex-cols-1 relative w-full text-center top-0">
                    <div className="grid grid-cols-1">
                      <div
                        id="toogle-funcionalidad"
                        className="relative col-span-1 swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      ></div>
                    </div>
                    <div className="grid grid-cols-2 w-full gap-1">
                      <div className="relative text-poppins-regular text-black flex">
                        <span>Funcionalidades</span>
                      </div>

                      <div className="text-white relative flex justify-end lg:justify-star">
                        <div className="relative flex theme-switch-wrapper">
                          <label
                            className="theme-switch"
                            htmlFor="chkFuncionalidad"
                          >
                            <input
                              type="checkbox"
                              id="chkFuncionalidad"
                              tabIndex="1"
                            />
                            <div
                              className="slider-cookie-consent round-cookie"
                              tabIndex="1"
                              id="sliderChkFuncionalidad"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="info_funcionalidad"
                  className="hidden relative btn-link-cookie flex w-11/12 text-center mx-auto md:w-7/12"
                >
                  <p className="text-left text-black">
                    Estas cookies permiten recordar las elecciones que haces
                    para ofrecerte una experiencia más personalizada. También
                    pueden utilizarse para proporcionar características
                    mejoradas y personalizadas.
                  </p>
                </div>

                <div className="relative flex w-11/12 text-center mx-auto md:w-7/12">
                  <div className="h-full items-center btn-link-cookie gap-2 flex flex-cols-1 relative w-full text-center top-0">
                    <div className="grid grid-cols-1">
                      <div
                        id="toogle-otros"
                        className="relative col-span-1 swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      ></div>
                    </div>
                    <div className="grid grid-cols-2 w-full gap-1">
                      <div className="relative text-poppins-regular text-black flex">
                        <span>Otros</span>
                      </div>

                      <div className="text-white relative flex justify-end lg:justify-star">
                        <div className="relative flex theme-switch-wrapper">
                          <label className="theme-switch" htmlFor="chkOtros">
                            <input type="checkbox" id="chkOtros" tabIndex="1" />
                            <div
                              className="slider-cookie-consent round-cookie"
                              tabIndex="1"
                              id="sliderChkOtros"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="info_otros"
                  className="hidden relative btn-link-cookie flex w-11/12 text-center mx-auto md:w-7/12"
                >
                  <p className="text-left text-black">
                    Esta categoría incluye cookies que no encajan en las
                    anteriores, como las que se utilizan para la integración de
                    redes sociales o para medir el rendimiento del sitio. Estas
                    cookies pueden ser gestionadas por terceros.
                  </p>
                </div>

    
    <div className="relative pt-8 px-4 pb-4 text-right rounded-3xl">
                  <button className="relative guardar_cookie mitexto_color w-full text-poppins-bold btn-link hover:bg-green-800 md:w-2/5">
                    Guardar y aceptar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
)
export default IndexPage
